.home-button {
    background-image: url('./img/home.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
    height: 10vw;
    width: 10vw;
    cursor: pointer;
    padding: 1.5vw;
    border-radius: 5vw;
    border: 5px solid #000;
    transition: background-color 0.5s ease;
    background-color: #ffffff33;
    position: absolute;
    top: 2vw;
    left: 2vw;
}