@keyframes scroll {
    from { background-position: 0px center; }
    to { background-position: 100vw center; }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
    from {opacity: 0.2;}
    to {opacity: 1;}
}

/* Standard syntax */
@keyframes fadeIn {
    from {opacity: 0.2;}
    to {opacity: 1;}
}

.panorama-container {
    -webkit-animation: fadeIn 2s ; /* Chrome, Safari, Opera */
    animation: fadeIn 2s ;
}

.panorama {
    height: 100vh;
    width: 100vw;
    background-color: #343a40;
    background-size: contain;
    background-position: center;
    margin: 0;
}

.repeat-active {
    background-repeat: repeat-x;
}

.repeat-disabled {
    background-repeat: no-repeat;
}

.page-text {
    background-color: #ffffffbb;
    white-space:pre-wrap;
    color: #000;
    font-size: 3vw;
    font-weight: bold;
    padding: 1vw;
    border-radius: 1vw;
    max-width: 80%;
}

.toggle-page-text {
    background-image: url('./img/text.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
    height: 10vw;
    width: 10vw;
    cursor: pointer;
    padding: 2vw 1.5vw 1.5vw 1.5vw;
    border-radius: 5vw;
    border: 5px solid #000;
    transition: background-color 0.5s ease;
    background-color: #ffffff33;
}

.top-left {
    position: absolute;
    top: 2vw;
    left: 2vw;
}

.top-right {
    position: absolute;
    top: 2vw;
    right: 2vw;
}

.bottom-left {
    position: absolute;
    bottom: 2vw;
    left: 2vw;
}

.bottom-right {
    position: absolute;
    bottom: 2vw;
    right: 2vw;
}

.toggle-scroll {
    background-image: url('./img/arrows.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
    height: 10vw;
    width: 10vw;
    cursor: pointer;
    padding: 1vw;
    border-radius: 5vw;
    border: 5px solid #000;
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    transition: background-color 0.5s ease;
}

.scroll-active {
    background-color: #ffffff33;
}

.scroll-disabled {
    background-color: #ff000066;
}