@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html, #root {
  color: #fff !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #343a40;
}
body {
  margin: 0;
}

.bg-grey {
  background-color: #343a40 !important;
}
.bg-light-grey {
  background-color: #404040 !important;
}

.span-height {
  min-height: 100vh;
}
.span-width {
  min-width: 100vw;;
}
