.book-tile {
    cursor: pointer;
}

/* Mobile */
@media only screen
  and (max-device-width: 575px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .book-tile {
        margin-left: 4vw;
        margin-right: 4vw;
    }
}

/* Tablet */
@media only screen
  and (max-device-width: 768px) 
  and (min-device-width: 575px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .book-tile {}
}

/* Desktop */
@media only screen 
  and (min-device-width: 768px) {
    .book-tile {
        max-width: 30vw;
    }
}
